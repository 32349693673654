(function () {
  'use strict';

  class VehicleRegistrationController {
    constructor($scope, $log, Authenticator, safetyService, FeatureOuService) {
      this.authenticator = Authenticator;
      this.log = $log;
      this.scope = $scope;
      this.safetyService = safetyService;
      this.FeatureOuService = FeatureOuService;
      this.safetyApp = 'safetyApp';
      this.safetyElement = 'safety-app';
      this.document = angular.element(document)[0];
      this.window = window;
      this.roots = {};
      this.scope.$on('$destroy', () => {
        this.unmountReactComponent();
      });
      this.initUserConfig();
    }

    loadReactComponent(componentPath = this.modalComponent, componentId = this.safetyElement, props = {}) {
      this.unmountReactComponent(componentId);
      return this.safetyService.initialize()
        .then(() => this.safetyService.getComponent(this.safetyApp, componentPath))
        .then((component) => {
          const container = this.document.getElementById(componentId);
          if (container) {
            let root = this.roots[componentId];
            if (!root) {
              root = this.window.ReactDOM.createRoot(container);
              this.roots[componentId] = root;
            }
            root.render(
              this.window.React.createElement(component().default, props)
            );
          }
        })
        .catch((error) => {
          this.log.error(`Error loading component at ${componentPath}: `, error);
        });
    }

    unmountReactComponent(componentId = this.safetyElement) {
      const container = this.document.getElementById(componentId),
          root = this.roots[componentId];
      if (container && root) {
        root.unmount();
        delete this.roots[componentId];
      }
    }

    initUserConfig() {
      this.authenticator.getUser().then(user => {
        this.isAdministrator = this.isUserAdministrator(user.perfil.id);
        this.getVehicleRegistrationConfig(user.uo.id);
      });
    }

    isUserAdministrator(perfilId) {
      const AdminProfiles = {
        TRIMBLE_ADMIN: 1,
        ADMIN: 2,
        BI_ADMIN: 1216
      };
      return [AdminProfiles.TRIMBLE_ADMIN, AdminProfiles.ADMIN, AdminProfiles.BI_ADMIN].includes(perfilId);
    }

    shouldDisplayRegisterVehicleButton() {
      return this.displayRegisterVehicleButton;
    }

    handleRegisterVehicleButtonClick() {
      if (this.isAdministrator) {
        this.loadReactComponent(
          './VehicleRegistrationModal',
          'vehicleRegistrationModal',
          {
            onRegistrationSuccess: () => {
              this.scope.$apply(() => this.scope.$emit('vehicleRegistered'));
            }
          }
        );
      }
    }

    getVehicleRegistrationConfig(userUoId) {
      this.FeatureOuService.getVehicleRegistrationConfig(userUoId).then((vehicleRegistration) => {
        this.displayRegisterVehicleButton = vehicleRegistration;
      }).catch((error) => {
        this.log.error('Error loading featureOu config: ', error);
      });
    }
  }

  VehicleRegistrationController.$inject = ['$scope', '$log', 'Authenticator', 'safetyService', 'FeatureOuService'];
  angular.module('safetyModule').controller('VehicleRegistrationCtrl', VehicleRegistrationController);
})();
