(function () {
  'use strict';

  /* global document, Event */
  class ProntuarioCtrl {
    constructor($uibModal, moment, Keys, ServiceVfiltro, FactoryVfiltro) {
      this.modalService = $uibModal;
      this.moment = moment;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.factoryVfiltro = FactoryVfiltro;

      this.consultar();
    }

    consultar() {
      this.serviceVfiltro.init(this.factoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          showSegmentPersonalizado: false,
          format: 'YYYY-MM-DD',
          defaultValues: this.getDefaultValuesFiltro()
        },
        {
          name: 'uoId',
          key: this.keys.uo.name
        },
        {
          name: 'motorista',
          key: this.keys.motorista.name,
          required: true
        }
      ]))
      .then((filtro) => {
        this.vfiltroBackend = this.serviceVfiltro.getFiltroBackendNovo(filtro);
        this.setupProntuarioTassi(this.vfiltroBackend);
        this.isAdminVeltec = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('ADMIN_VELTEC');
      });
    }

    setupProntuarioTassi(filtro) {
      /*eslint-disable */
      const prontuarioDivElement = document.querySelector('#prontuario-tassi'),
        prontuarioElement = document.createElement('vfwc-prontuario-motorista');
      prontuarioElement.uoId = filtro.uoId;
      prontuarioElement.inicio = filtro.inicio;
      prontuarioElement.fim = filtro.fim;
      prontuarioElement.motoristaId = filtro.motorista;
      prontuarioElement.usuario = this.serviceVfiltro.factoryVfiltro.user;
      /*eslint-enable */

      prontuarioDivElement.appendChild(prontuarioElement);
      this.onAbrirModalEditarInfracao(prontuarioElement);
    }

    onAbrirModalEditarInfracao(element) {
      element.addEventListener('abrirModalImportarInfracoes', (event) => {
        this.openModalUpload(event.detail);
      });
    }

    getDefaultValuesFiltro() {
      return {
        mesAtual: {
          active: this.geCurrentDay() !== 1
        },
        ultimoMes: {
          active: true
        },
        ultimos3Meses: {
          active: true
        },
        ultimos6Meses: {
          active: true
        }
      };
    }

    geCurrentDay() {
      return this.moment().date();
    }

    openModalUpload(arquivo = null) {
      this.modalService.open({
        animation: true,
        templateUrl: 'prontuario/modal/upload/upload-prontuario.tpl.html',
        controller: 'UploadProntuarioCtrl',
        controllerAs: '$ctrl',
        resolve: {
          motoristaId: () => this.vfiltroBackend.motorista,
          arquivo: () => arquivo,
          user: () => this.serviceVfiltro.factoryVfiltro.user
        }
      })
      .result
      .then(() => {
        /*eslint-disable */
        const ev = new Event('reload-infracoes-prontuario');
        document.dispatchEvent(ev);
        /*eslint-enable */
      });
    }

    handleGoToNewProntuario() {
      return '/prontuario-v2';
    }
  }

  angular
    .module('ProntuarioModule')
    .controller('ProntuarioCtrl', ProntuarioCtrl);
}());
