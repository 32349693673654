(function () {
  'use strict';

  class ProntuarioV2Ctrl {
    constructor(Keys, Authenticator, ServiceVfiltro, FactoryVfiltro, safetyService, SolucoesEnum, FeatureFlag, $scope, $window, $document, $log) {
      this.keys = Keys;
      this.authenticator = Authenticator;
      this.serviceVfiltro = ServiceVfiltro;
      this.factoryVfiltro = FactoryVfiltro;
      this.safetyService = safetyService;
      this.featureFlag = FeatureFlag;
      this.solucoesEnum = SolucoesEnum;
      this.scope = $scope;
      this.window = $window;
      this.document = $document[0];
      this.log = $log;

      this.safetyApp = 'safetyApp';
      this.pageComponent = './Prontuario';
      this.safetyElement = 'safety-app';
      this.component = null;
      this.container = this.document.getElementById(this.safetyElement);
      this.solucaoId = this.featureFlag.SOLUCAO_REC_FACIAL ? this.solucoesEnum.RECONHECIMENTO_FACIAL : null;
      this.consultar();

      this.scope.$on('$destroy', () => {
        this.unmountReactComponent();
      });
    }

    consultar() {
      this.serviceVfiltro
        .init(
          this.factoryVfiltro.get([
            {
              name: 'uoId',
              key: this.keys.uo.name
            },
            {
              key: this.keys.dateTime.name,
              dateLimit: 30,
              format: 'YYYY-MM-DD',
              defaultValues: {
                ontem: {
                  active: true
                },
                ultimos7dias: {
                  active: true
                },
                ultimos30dias: {
                  active: true
                }
              }
            },
            {
              name: 'motorista',
              key: this.keys.motorista.name,
              required: true
            }
          ])
        )
        .then((filtro) => {
          this.authenticator.getUser().then(user => {
            this.loadReact(this.serviceVfiltro.getFiltroBackendNovo(filtro), user);
          });
        });
    }

    loadReact(filtro, user) {
      this.safetyService
        .initialize()
        .then(() =>
          this.safetyService.getComponent(this.safetyApp, this.pageComponent)
        )
        .then((page) => {
          this.component = page().default;
          this.loadComponent(filtro, user);
        })
        .catch((error) => {
          this.log.error('Error occurred: ', error);
        });
    }

    loadComponent(filtro, user) {
      this.window.ReactDOM.render(
        this.window.React.createElement(this.component, {
          driverId: filtro.motorista,
          endDate: filtro.fim,
          startDate: filtro.inicio,
          uoId: filtro.uoId,
          user: user,
          solucaoRecFacialId: this.solucaoId
        }),
        this.container
      );
    }

    handleGoToOldProntuario() {
      return '/prontuario';
    }

    unmountReactComponent() {
      if (this.window.ReactDOM && this.container) {
        if (this.window.ReactDOM.unmountComponentAtNode(this.container)) {
          this.log.log('React component unmounted');
        } else {
          this.log.log('React component was not mounted.');
        }
      } else {
        this.log.log('Container not found or React component was not mounted.');
      }
    }
  }

  angular
    .module('ProntuarioV2Module')
    .controller('ProntuarioV2Ctrl', ProntuarioV2Ctrl);
}());
