/* global document */
(function () {
  'use strict';

  class PlaybackCtrl {
    constructor(ServiceVfiltro, FactoryVfiltro, Keys, moment, Authenticator) {
      this.authenticator = Authenticator;
      this.keys = Keys;
      this.moment = moment;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name,
          required: true,
          order: 1
        },
        {
          key: this.keys.veiculo.name,
          required: true,
          checkVehiclesOnline: true,
          reloadCalendar: true,
          order: 2
        },
        {
          key: this.keys.dateTime.name,
          format: 'YYYY-MM-DD',
          maxDate: this.moment().endOf('day').format(),
          customAvailableDates: true,
          required: true,
          order: 3
        }
      ]))
      .then((filtro) => {
        this.init(filtro);
      });
    }

    init(filtro) {
      this.authenticator.getUser().then(user => {
        /*eslint-disable */
        const playbackPageContentElement = document.querySelector('#playback-page-content');
        
        const vfwcPlaybackElement = document.createElement('vfwc-playback'),
          vehicleFilter = filtro.find(f => f.key === this.keys.veiculo.name),
          dateTimeFilter = filtro.find(f => f.key === this.keys.dateTime.name);

        vfwcPlaybackElement.vehicleId = vehicleFilter && Number(vehicleFilter.value.id);
        vfwcPlaybackElement.startDate = dateTimeFilter && dateTimeFilter.value.startDate;
        vfwcPlaybackElement.endDate = dateTimeFilter && dateTimeFilter.value.endDate;
        vfwcPlaybackElement.user = user;
        playbackPageContentElement.appendChild(vfwcPlaybackElement);
        /*eslint-enable */
      });
    }
  }

  angular
    .module('relatorios.videoLibrary.playback')
    .controller('PlaybackCtrl', PlaybackCtrl);
}());
