(function () {
  'use strict';

  class RankingPontosCtrl {
    constructor($filter, $httpParamSerializer, moment, RankingPontos, Keys, ServiceVfiltro,
      FactoryVfiltro, UrlService, FormatVfiltro, ObjetosVfiltroService, VtabelaFactory, uiGridConstants, $translate,
      UrlConfigService, MeasurementUnitConverterService, Authenticator, ConvertersService) {
      this.filter = $filter;
      this.httpParamSerializer = $httpParamSerializer;
      this.moment = moment;
      this.rankingPontos = RankingPontos;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.urlService = UrlService;
      this.formatVfiltro = FormatVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;
      this.vtabela = null;
      this.exportsPdf = false;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.convertersService = ConvertersService;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.labelSemMotorista = this.translate.instant('ce.ranking.pontos.labelSemMotorista');
      this.labelNoPermissionVehicle = this.translate.instant('ce.ranking.pontos.labelNoPermissionVehicle');
      this.ativoVtabela = this.translate.instant('ce.ranking.pontos.colunas.ativoUpperCase');
      this.inativoVtabela = this.translate.instant('ce.ranking.pontos.colunas.inativoUpperCase');
      this.pontos100Km = this.translate.instant('ce.ranking.pontos.colunas.pontos100Km');
      this.stringProntuario = this.translate.instant('ce.ranking.pontos.stringProntuario');
      this.inactiveDriver = this.translate.instant('ce.ranking.pontos.inactiveDriver');
      this.vfiltro = {};

      Authenticator.getUser().then(user => {
        this.usuario = user;
      });

      ServiceVfiltro.init(FactoryVfiltro.get(this.getDefaultValuesFiltro()))
      .then(() => {
        let dateTime = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.dateTime.name).value;
        this.vfiltro = {
          [this.keys.dateTime.name]: `${this.moment(dateTime.endDate).subtract(30, 'days').format(this.formatVfiltro)},${this.moment(dateTime.endDate).add(1, 'days').format(this.formatVfiltro)}`,
          [this.keys.uo.name]: this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id
        };
        this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.usuario.measurementUnits.distanceMeasurement);
        this.megNameColumn = this.translate.instant('ce.ranking.pontos.colunas.meg', {distanceUnit: this.distanceUnit});
        this.roleVisualizarBdm = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('BOLETIM_MOTORISTA_VISUALIZAR');
        this.roleVisualizarBdv = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('BOLETIM_VEICULO_VISUALIZAR');

        this.consultar(this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar));
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'rankingPontos', 'v2');
      });
    }

    getDefaultValuesFiltro() {
      return [
        {
          key: this.keys.dateTime.name,
          showSegmentPersonalizado: false,
          format: 'YYYY-MM-DD',
          defaultValues: {
            mesAtual: {
              active: this.getCurrentDay() !== 1
            },
            ultimoMes: {
              active: true
            },
            ultimos3Meses: {
              active: true
            },
            ultimos6Meses: {
              active: true
            }
          }
        },
        {
          name: 'uoId',
          key: this.keys.uo.name
        },
        {
          name: 'uoDonaId',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'motoristasId',
          key: this.keys.motoristas.name,
          size: 6
        }
      ];
    }

    initVtabela() {
      let objVtabela = {
        exporterPdfFilename: 'Ranking Motoristas PDF.pdf',
        exporterCsvFilename: 'Ranking Motoristas CSV.csv',
        exporterExcelFilename: 'Ranking Motoristas XLSX.xlsx',
        appScopeProvider: this,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        multiSelect: false,
        enableSelectAll: false,
        showColumnFooter: true,
        isRowSelectable: () => {
          return false;
        },
        columnDefs: [
            {
              name: 'classificacaoGeral',
              displayName: 'ce.ranking.pontos.colunas.posicao',
              type: 'number',
              cellClass: 'text-center',
              filterCellFiltered: true,
              pinnedLeft: true,
              aggregationTotalTitle: 'Total'
            },
            {
              name: 'motorista',
              displayName: 'ce.ranking.pontos.colunas.motorista',
              pinnedLeft: true,
              type: 'string',
              filterCellFiltered: true,
              enableHiding: false,
              cellTemplate: `
              <div class='ui-grid-cell-contents' title="{{row.entity.ic}}">
                <a
                ng-href='{{(row.entity.linkBdm)}}'
                target='_blank'
                ng-if='grid.appScope.roleVisualizarBdm && row.entity.motorista !== grid.appScope.labelSemMotorista && row.entity.linkBdm'
                title="{{row.entity.motorista}}">
                  {{row.entity.motorista}}
                </a>
                <span
                ng-if='!grid.appScope.roleVisualizarBdm || row.entity.motorista === grid.appScope.labelSemMotorista''
                title="{{row.entity.motorista}}">
                  {{row.entity.motorista | splitComposedLabels:true}}
                </span>
              </div>
              `
            },
            {
              name: 'departamento',
              displayName: 'ce.ranking.pontos.colunas.departamento',
              type: 'string',
              filterCellFiltered: true,
              enableHiding: false
            },
            {
              name: 'veiculos',
              displayName: 'ce.ranking.pontos.colunas.veiculos',
              enableHiding: false,
              filterCellFiltered: true,
              cellTemplate: `
              <div class='ui-grid-cell-contents' title="{{row.entity.ic}}">
                <a ng-href='{{(row.entity.linkBdv)}}'
                target='_blank'
                ng-if='grid.appScope.roleVisualizarBdv && row.entity.veiculos && grid.appScope.hasVehiclePermission(row.entity.veiculos)'
                title="{{row.entity.veiculos}}">
                  {{row.entity.veiculos}}
                </a>
                <span
                ng-if='!grid.appScope.roleVisualizarBdv || !row.entity.veiculos || !row.entity.linkBdv || !grid.appScope.hasVehiclePermission(row.entity.veiculos)'
                title="{{row.entity.veiculos}}">
                  {{row.entity.veiculos}}
                </span>
              </div>
              `
            },
            {name: 'tempoIgnicaoLigada', displayName: 'ce.ranking.pontos.colunas.tempoIgnicaoLigada', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
            cellFilter: 'humanizedDuration', cellClass: 'text-center', enableFiltering: false},
            {name: 'distanciaPercorrida', displayName: 'ce.ranking.pontos.colunas.distanciaPercorrida', type: 'number', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
            cellFilter: 'decimalWithLabel:\'' + this.distanceUnit + '\'', cellClass: 'text-center', enableFiltering: false},
            {name: 'pontosPorHora', displayName: 'ce.ranking.pontos.colunas.pontosPorHora', cellFilter: 'decimal:\'' + this.distanceUnit + '\'', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
            cellClass: 'text-center', enableFiltering: false},
            {name: 'pontos100Km', displayName: this.getPointsPerUnits(), cellFilter: 'decimalForPoints:\'' + this.distanceUnit + '\'', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
            cellClass: 'text-center', enableFiltering: false},
            {
              name: 'meg',
              displayName: this.megNameColumn,
              type: 'number',
              cellFilter: 'decimal:\'' + this.distanceUnit + '\'',
              cellClass: 'text-center',
              enableFiltering: false,
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'megHoras',
              displayName: 'ce.ranking.pontos.colunas.megHoras',
              type: 'number',
              cellFilter: 'decimal:\'' + this.distanceUnit + '\'',
              cellClass: 'text-center',
              enableFiltering: false,
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {name: 'pontosTotal', displayName: 'ce.ranking.pontos.colunas.pontosTotal', cellFilter: 'decimal:\'' + this.distanceUnit + '\'', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
            cellClass: 'text-center', enableFiltering: false},
            {
              name: 'ativo',
              displayName: 'ce.ranking.pontos.colunas.status.statusLabel',
              type: 'boolean',
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                  {value: true, label: this.translate.instant('ce.ranking.pontos.colunas.status.ativos')},
                  {value: false, label: this.translate.instant('ce.ranking.pontos.colunas.status.inativos')}
                ]
              },
              cellTemplate: `<div class="ui-grid-cell-contents text-center">{{row.entity.ativo ? grid.appScope.ativoVtabela : grid.appScope.inativoVtabela}}</div>`
            },
            {
              name: 'prontuario',
              displayName: '',
              enableFiltering: false,
              enableSorting: false,
              pinnedRight: true,
              enableColumnResizing: false,
              enableHiding: false,
              exporterSuppressExport: true,
              width: 70,
              cellTemplate: `
                <div class="ui-grid-cell-contents vtabela-icone-prontuario">
                  <a ng-if='row.entity.ativo' ng-href='{{(row.entity.linkProntuario)}}' title='{{ grid.appScope.stringProntuario }}'>
                    <span class="trimble-driver-record" aria-hidden='true'></span>
                  </a>
                  <span ng-if='!row.entity.ativo' class="trimble-driver-record" aria-hidden='true' uib-tooltip="{{ grid.appScope.inactiveDriver }}"></span>
                </div>`
            }
        ]
      };
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        id: item.motoristas.id,
        motorista: this.filter('withLabelToUndefined')(item.motoristas.nome, this.labelSemMotorista),
        departamento: item.departamentos.nome,
        veiculos: item.veiculosExtenso ? item.veiculosExtenso : this.labelNoPermissionVehicle,
        tempoIgnicaoLigada: item.tempoEmDirecao,
        distanciaPercorrida: item.distanciaPercorrida,
        pontosPorHora: item.pontosPorHora,
        pontos100Km: item.pontos100Km,
        pontosTotal: item.pontosTotal,
        ativo: item.motoristas.ativo,
        linkBdv: this.getLinkBdv(item.veiculos),
        linkBdm: this.getLinkBdm(item.motoristas.id),
        linkProntuario: this.getLinkProntuario(item.motoristas.id),
        classificacaoGeral: item.classificacaoGeral,
        meg: this.handleDistanceUnitConversion(item.meg),
        megHoras: item.megHoras
      };
    }

    getCurrentDay() {
      return this.moment().date();
    }

    consultar() {
      this.rankingPontos.one()
        .get(this.getParams())
        .then(data => this.setInfoTable(data))
        .catch(() => this.vtabela.setError());
    }

    setInfoTable(data) {
      let content = this.buildContent(data.content);
      this.vtabela.setData(content.map(i => this.getItemVtabela(i)));
    }

    buildContent(content) {
      return this.filter('orderBy')(
        content.map(item => angular.extend(item, {
          veiculosExtenso: item.veiculos
            .filter(vei => vei.prefixo || vei.placa)
            .map(vei => {
              if (!vei.prefixo || vei.prefixo === vei.placa) {
                return vei.placa;
              }
              return `${vei.prefixo} - ${vei.placa}`;
            })
            .join(', '),
          motoristas: item.motoristas && item.motoristas[0] || {},
          departamentos: item.departamentos && item.departamentos[0] || {}
        })),
        'classificacaoGeral', true);
    }

    setTableState(tableState) {
      if (this.$parent.rankingPontos.tableState !== null) {
        tableState.sort = this.$parent.rankingPontos.tableState.sort;
      }
      this.$parent.rankingPontos.tableState = tableState;
    }

    getParams() {
      const fields = [
        'motorista.id',
        'motorista.nome',
        'motorista.ativo',
        'departamento.id',
        'departamento.nome',
        'veiculo.id',
        'veiculo.placa',
        'veiculo.prefixo',
        'tempoEmDirecao',
        'distanciaPercorrida',
        'pontosTotal',
        'classificacaoGeral',
        'meg',
        'megHoras'
      ];

      return Object.assign(
        {fields: fields.toString()},
        this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar),
        {groupFields: 'motoristaId'}
      );
    }

    getUrlCsv() {
      return `/boletim/ranking/pontuacao-motoristas/mensal/csv?${this.httpParamSerializer(this.getParams())}`;
    }

    getUrlPdf() {
      return `/boletim/ranking/pontuacao-motoristas/mensal/pdf?${this.httpParamSerializer(this.getParams())}`;
    }

    getLinkBdv(veiculosIds) {
      let tablestate = this.objetosVfiltroService.getTableState(this.tableState),
          tablestateUrl = tablestate && `tablestate=${tablestate}&` || '',
          vfiltro = angular.copy(this.vfiltro);
      vfiltro[this.keys.veiculos.name] = veiculosIds.map(vei => vei.id).join(',');

      return `/relatorios/bdv?${tablestateUrl}vfiltro=${this.urlService.getObjectVfiltroUrl(vfiltro)}`;
    }

    getLinkBdm(motoristaId) {
      let tablestate = this.objetosVfiltroService.getTableState(this.tableState),
          tablestateUrl = tablestate && `tablestate=${tablestate}&` || '',
          vfiltro = angular.copy(this.vfiltro);
      vfiltro[this.keys.motoristas.name] = motoristaId;
      return `/relatorios/bdm?${tablestateUrl}vfiltro=${this.urlService.getObjectVfiltroUrl(vfiltro)}`;
    }

    getLinkProntuario(motoristaId) {
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return `/prontuario?vfiltro=${this.keys.dateTime.name}:${this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.dateTime.name).value.tipo};${this.keys.uo.name}:${uoId};${this.keys.motorista.name}:${motoristaId}`;
    }

    getPointsPerUnits() {
      return `${this.pontos100Km} ${this.distanceUnit}`;
    }

    hasVehiclePermission(vehicle) {
      return vehicle !== this.labelNoPermissionVehicle;
    }

    handleDistanceUnitConversion(value) {
      return this.usuario.measurementUnits.distanceMeasurement === 'MILES'
        ? this.convertersService.handleKmToMilesWithDecimal(value, 2)
        : value;
    }
  }

  angular
    .module('ranking.Pontos')
    .controller('RankingPontosCtrl', RankingPontosCtrl);
}());
